import React, { FC } from "react"
import styled from "styled-components"
import Head from "~/components/Head"
import Layout from "~/components/Layout"

const Wrapper = styled.div`
  margin: ${({ theme }) => theme.space.topTitlePage};
  text-align: center;
`

const Title = styled.h1`
  font-size: ${({ theme }) => theme.fontSize.title};
`

const Form = styled.iframe`
  width: 100%;
  height: 1050px;
  border: none;
  ${({ theme }) => theme.media.lm`
    height: 1200px;
  `}
  ${({ theme }) => theme.media.sm`
    height: 1300px;
  `}
`

const ContactPage: FC = () => {
  return (
    <>
      <Head title="Contact" />
      <Layout>
        <Wrapper>
          <Title>Contact</Title>
          <Form src="https://docs.google.com/forms/d/e/1FAIpQLSf8mEK4hDKNwtjOIPpg_PQ0Wj1jUj_UxXUCUW-dV5GHFhvfew/viewform?embedded=true">
            読み込んでいます…
          </Form>
        </Wrapper>
      </Layout>
    </>
  )
}

export default ContactPage
